<template>
  <div class="login">
    <div class="header">
      <div class="close">
        <van-icon name="cross" size="20px" color="#ffffff" @click="goback" />
      </div>
      <div class="text">账号登录</div>
      <!-- <div class="registerText" @click="toRegister">注册</div> -->
    </div>

    <div class="bg"></div>

    <div class="content">
      <div class="form">
        <div class="welcome">欢迎登录证小二</div>
        <div class="username">
          <!-- <div class="usernameText">账号</div> -->
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder="请输入用户名"
          />
          <img src="../assets/images/icon/username.png" alt="" />
        </div>
        <div class="password">
          <!-- <div class="passwordText">密码</div> -->
          <input
            type="password"
            id="password"
            v-model="password"
            placeholder="请输入密码"
          />
          <img src="../assets/images/icon/password.png" alt="" />
        </div>

        <van-checkbox v-model="checked" icon-size="14px">
          <div class="serverText" style="font-size: 12px; color: #999999">
            我已阅读并接受
            <span style="color: #1989fa" @click.stop="showServiceNote = true"
              >《证小二演示版服务说明》</span
            >
          </div>
        </van-checkbox>

        <button class="loginButton" @click="login">登录</button>

        <!-- <div class="forgetPassword">忘记密码</div> -->
        <div class="hint">请输入账号admin、密码123456进行登录</div>
      </div>
      <div class="other">
        <div class="otherTitle">
          <span class="titleText">快捷登录</span>
        </div>
        <div class="otherButton" @click="toFaceIdentify">
          <img src="../assets/images/icon/faceIdentifyIcon2.png" alt="" />
        </div>
        <div class="hintText">人脸登录</div>
      </div>
    </div>

    <van-popup v-model="showServiceNote" :style="{ width: '80%' }">
      <div class="serviceNote">
        <div class="closeNote">
          <van-icon name="cross" size="20px" @click="showServiceNote = false" />
        </div>
        <h3>《证小二演示版服务说明》</h3>
        <div class="noteText">
          <p>
            1.
            您正在使用的证小二应用为先行演示版，演示版本的功能不代表产品最终上线的所有功能；
          </p>
          <p>2. 证小二著作权属于广州道可维斯。</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null,

      usernameNorm: "admin",
      passwordNorm: "123456",
      checked: true,
      showServiceNote: false,
    };
  },
  methods: {
    // 返回首页
    goback() {
      this.$router.push("/");
    },

    // 跳转人脸识别登录
    toFaceIdentify() {
      this.$router.push("/faceIdentify");
    },

    // 跳转注册页面
    // toRegister() {
    //   this.$router.push("/register");
    // },

    // 跳转服务协议
    toServiceNote() {
      this.$router.push("/serviceNote");
    },

    // 登录
    login() {
      if (this.checked) {
        if (this.username && this.password) {
          Toast.loading({
            message: "正在登录...",
            forbidClick: true,
            loadingType: "spinner",
            duration: 0,
          });
          this.$router.push("/home");
          // this.$axios
          //   .post("https://emonitor.docuvix.com/lmuserservice/api/v1/UserLogin", {
          //     Account: this.username,
          //     Password: this.password,
          //   })
          //   .then((res) => {
          //     console.log(res);
          //     if (res.data.success) {
          //       Toast.clear();
          //       this.$router.push("/home");
          //       sessionStorage.setItem('code',res.data.code)
          //       sessionStorage.setItem('realname',res.data.data[0].realname)
          //       sessionStorage.setItem('companyname',res.data.data[0].companyname)
          //       sessionStorage.setItem('headpic',res.data.data[0].headpic)
          //     } else {
          //       Toast("登录失败," + res.data.msg);
          //     }
          //   })
          //   .catch((err) => {});
        } else {
          Toast("请输入账号/密码");
        }
      } else {
        Toast("请先阅读并勾选《证小二演示版服务说明》");
      }
    },
  },
};
</script>

<style lang="less"  scoped >
.login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  // background: linear-gradient(to bottom, #1989fa,#ffffff);
  // background: #1989fa;

  input {
    font-size: 14px;
  }

  .header {
    width: 100%;
    height: 44px;
    margin-bottom: 5vh;
    position: relative;
    top: 0;
    left: 0;
    z-index: 99;

    .close {
      position: absolute;
      top: 12px;
      left: 10px;
    }

    .text {
      width: 100%;
      font-size: 18px;
      color: #ffffff;
      line-height: 44px;
      text-align: center;
    }

    .registerText {
      font-size: 18px;
      line-height: 20px;
      color: #ffffff;
      position: absolute;
      top: 12px;
      right: 10px;
    }
  }

  .bg {
    width: 100%;
    height: 40vh;
    background-image: url("../assets/images/bg/loginbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .content {
    width: 100vw;
    height: calc(95vh - 44px);
    background-color: #ffffff;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;

    .form {
      width: 100vw;
      // height: 50vh;
      padding: 50px 30px;
      box-sizing: border-box;
      // margin-bottom: 50px;

      .welcome {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        color: #1989fa;
        margin-bottom: 20px;
      }

      .username {
        width: 100%;
        height: 40px;
        padding-left: 40px;
        margin-bottom: 10px;
        border-radius: 8px;
        overflow: hidden;
        box-sizing: border-box;
        background-color: #f5f5f5;
        text-align: left;
        position: relative;

        img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 12px;
          left: 12px;
        }

        #username {
          width: 100%;
          height: 100%;
          background-color: transparent;
          border: none;
        }
      }

      .password {
        width: 100%;
        height: 40px;
        padding-left: 40px;
        margin-bottom: 15px;
        border-radius: 8px;
        overflow: hidden;
        box-sizing: border-box;
        background-color: #f5f5f5;
        text-align: left;
        position: relative;

        img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 12px;
          left: 12px;
        }

        #password {
          width: 100%;
          height: 100%;
          background-color: transparent;
          border: none;
        }
      }

      .loginButton {
        width: 100%;
        height: 44px;
        line-height: 44px;
        border-radius: 8px;
        color: #ffffff;
        background-color: #1989fa;
        text-align: center;
        border: none;
        outline: none;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .hint {
        font-size: 12px;
        text-align: left;
        color: #1989fa;
        // color: #666666;
      }
    }

    .other {
      width: 100%;

      .otherTitle {
        text-align: center;
        color: #999999;

        span {
          position: relative;

          &::before {
            content: "";
            width: 8px;
            height: 2px;
            background-color: #999999;
            position: absolute;
            top: 50%;
            left: -13px;
            transform: translateY(-50%);
          }

          &::after {
            content: "";
            width: 8px;
            height: 2px;
            background-color: #999999;
            position: absolute;
            top: 50%;
            right: -13px;
            transform: translateY(-50%);
          }
        }
      }

      .otherButton {
        width: 60px;
        height: 60px;
        padding: 15px;
        box-sizing: border-box;
        border-radius: 50%;
        // background-color: #1989fa;
        margin: 10px auto 5px auto;
        box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);

        img {
          width: 30px;
          height: 30px;
        }
      }

      .hintText {
        font-size: 12px;
      }
    }
  }

  .van-popup.van-popup--center {
    border-radius: 10px;
  }

  .serviceNote {
    width: 100%;
    padding: 30px 20px;
    box-sizing: border-box;
    position: relative;

    .closeNote {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    h3 {
      margin-top: 0;
    }

    .noteText {
      text-align: left;
      font-size: 14px;
    }
  }
}
</style>
