<template>
  <div class="voucherServices1">
    <van-nav-bar fixed title="条码管理" left-arrow @click-left="goBack">
    </van-nav-bar>
    <!-- <div class="thisCode">
      <span class="codeLeft">当前条码</span>
      <span class="codeVaule">{{ thisCode }}</span>
    </div> -->
    <!-- <div class="btnMy">
      <van-button @click="tips" block type="info">打印新条码</van-button>
    </div> -->
    <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
      已使用的条码
    </van-notice-bar>
    <div class="codeLists">
      <div
        class="oneCode"
        v-for="(item, index) in codeLists"
        :key="index"
        @click="goDetail(item)"
      >
        <div class="oneCodeLfet">
          <p>卷宗：{{ item.juanzhong }}</p>
          <p>案卷：{{ item.anjuan }}</p>
          <p>当前条码：{{ item.dqtm }}</p>
        </div>
        <div class="rightIcon"><van-icon name="arrow" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";

export default {
  name: "barcodeLists",
  data() {
    return {
      show: false,
      thisCode: "340-AJ001-00004",
      codeLists: [
        {
          juanzhong: "3A0",
          anjuan: "KD001",
          dqtm:'TM-2012450656'
        },
        {
          juanzhong: "3A1",
          anjuan: "KD003",
           dqtm:'TM-2012450656'
        },
        {
          juanzhong: "3A2",
          anjuan: "KD002",
          dqtm:'TM-2012450656'
        },
        {
          juanzhong: "3A3",
          anjuan: "KD005",
          dqtm:'TM-2012450656'
        },{
          juanzhong: "3A4",
          anjuan: "KD0017",
          dqtm:'TM-2012450656'
        },
        
      ],
    };
  },
  methods: {
    tips() {
      Toast("请先连接扫描仪！");
    },
    goBack() {
      this.$router.push("/home");
    },
    goDetail(item) {
      console.log(item);
      this.$router.push({
        path: "/BarcodeDetails",
        query: {
          title: item.juanzhong +'_'+ item.anjuan,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped >
.codeLists {
  height: auto;
  margin-top: 5px;
  padding: 0 20px;
  box-sizing: border-box;
  .oneCode {
    height: 108px;
    border-bottom: 2px solid #eee;
    .oneCodeLfet {
      float: left;
      p {
        margin: 0;
        text-align: left;
        padding-top: 12px;
        padding-bottom: 0;
        // line-height: 28px;
      }
    }
    .rightIcon {
      float: right;
      height: 108px;
      line-height: 108px;
    }
  }
}

.thisCode {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 0 20px;
  border-bottom: 2px solid #eee;
  .codeLeft {
    float: left;
    height: 50px;
    text-align: left;
    line-height: 50px;
    font-size: 20px;
    font-weight: 900;
    width: 40%;
  }
  .codeVaule {
    float: left;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-weight: 900;
    text-align: right;
  }
}
.btnMy {
  padding: 10px 20px;
  box-sizing: border-box;
}
.voucherServices1 {
  box-sizing: border-box;
  padding-top: 45px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>