<template>
  <div class="me">
    <div class="header">
      <!-- <div class="close">
        <van-icon name="cross" size="20px" />
      </div> -->
      我的
    </div>

    <div class="userInfoCard">
      <div class="avatar">
        <input
          class="changeImgInput"
          @change="changeImg"
          type="file"
          accept="image/*"
          ref="myFileInput"
        />
        <img :src="src" alt="" />
      </div>

      <div class="info">
        <div class="username">{{ realname }}</div>
        <div class="name">{{ companyname }}</div>
        <!-- <div class="name">证小二</div> -->
      </div>
    </div>

    <div class="functionCard">
      <div class="function-item" @click="toMyUpload">
        <div class="icon">
          <img src="../assets/images/icon/uploadIcon.png" alt="" />
        </div>
        <div class="text">我上传的</div>
        <div class="right">
          <img src="../assets/images/icon/right.png" alt="" />
        </div>
      </div>
      <div class="function-item" @click="toHistory">
        <div class="icon">
          <img src="../assets/images/icon/cookies.png" alt="" />
        </div>
        <div class="text">浏览历史</div>
        <div class="right">
          <img src="../assets/images/icon/right.png" alt="" />
        </div>
      </div>
    </div>

    <div class="exitCard">
      <div class="exit" @click="exit">
        <div class="icon">
          <img src="../assets/images/icon/exitIcon.png" alt="" />
        </div>
        <div class="text">退出登录</div>
        <div class="right">
          <img src="../assets/images/icon/right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";

export default {
  name: "me",
  data() {
    return {
      userInfo: null,
      hover: false,
      src: require("../assets/images/images/avatar.png"),
      companyname: "",
      realname: "",
    };
  },
  methods: {
    //更换头像
    changeImg() {
      console.log("更换头像");
      let self = this;
      let file = event.target.files[0];
      let type = file.type;
      if (
        type.indexOf("pdf") < 0 &&
        type.indexOf("jpg") < 0 &&
        type.indexOf("jpeg") < 0 &&
        type.indexOf("png") < 0 &&
        type.indexOf("OFD") < 0
      ) {
        layer.alert("暂不支持这种类型的文件");
        return false;
      }
      if (file.size == 0) {
        return false;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = function () {
        console.log("图片正在上传处理......");
      };
      //操作完成
      reader.onload = function (e) {
        
        let fd = new FormData();
        fd.append("files", file);
        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        self.$axios
          .post(
            "https://emonitor.docuvix.com/lmuserservice/api/v1/UpdateUserHeadpic",
            fd,
            config
          )
          .then((res) => {
            console.log(res.data.success);
            if (res.data.success) {
              self.GetPersonalCenter()
            } else {
              Toast("头像上传失败");
            }
          })
          .catch((err) => {});
      };
      self.$refs.myFileInput.value = "";

      // $('#openInput').val('')
    },
    // 退出登录 （退出登录接口对接）
    exit() {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定要退出登录吗?",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          this.$router.push("/");
          this.$store.commit("setKeepAlive", []);

          // this.$axios
          //   .post("http://114.215.209.25:8001/api/v1/UserLogout")
          //   .then((res) => {
          //     console.log(res);

          //     // this.$router.push("/");
          //     // this.$store.commit("setKeepAlive", []);
          //   })
          //   .catch((err) => {});

          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },

    // 跳转我上传的
    toMyUpload() {
      this.$router.push("/myUpload");
    },

    // 跳转浏览历史
    toHistory() {
      this.$router.push("/history");
    },
    //获取个人中心
    GetPersonalCenter() {
      this.$axios
        .post(
          "https://emonitor.docuvix.com/lmuserservice/api/v1/GetPersonalCenter"
        )
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.realname = res.data.data[0].realname;
            this.companyname = res.data.data[0].companyname;
            this.src = res.data.data[0].headpic;
          } else {
            Toast("个人数据获取失败," + res.data.msg);
          }
        })
        .catch((err) => {});
    },
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    this.GetPersonalCenter();

    // this.realname = sessionStorage.getItem('realname')
    // this.companyname = sessionStorage.getItem('companyname')
    // this.src = sessionStorage.getItem('headpic')
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        from.fullPath == "/login" ||
        from.fullPath == "/faceIdentify" ||
        from.fullPath == "/register"
      ) {
        vm.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      }
    });
  },
};
</script>

<style lang="less" scoped >
.me {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .header {
    width: 100%;
    height: 44px;
    line-height: 44px;
    position: relative;

    // .close {
    //   height: 44px;
    //   padding-top: 4px;
    //   box-sizing: border-box;
    //   position: absolute;
    //   top: 0;
    //   left: 15px;
    // }

    .exit {
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      box-sizing: border-box;
      // box-shadow: 0 10px 20px 3px #00000013;
      background-color: #1989fa;
      color: #fff;
      position: absolute;
      top: 2px;
      right: 15px;
    }
  }

  .userInfoCard {
    margin: 10px auto;
    margin-bottom: 30px;
    width: calc(100% - 30px);
    height: 110px;
    padding: 15px;
    border-radius: 15px;
    box-sizing: border-box;
    background: linear-gradient(to right, #4ca6f5, #4c3dfc);
    box-shadow: 0 10px 20px 3px #4d3dfc0a;
    display: flex;

    .avatar {
      width: 70px;
      margin-right: 10px;
      position: relative;

      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
      .changeImgInput {
        position: absolute;
        width: 70px;
        height: 70px;
        opacity: 0;
      }
    }

    .info {
      flex: 1;
      color: #fff;
      text-align: left;

      .username {
        font-size: 20px;
        line-height: 45px;
      }

      // .name {
      //   font-size: 14px;
      //   line-height: 30px;
      // }
    }
  }

  .functionCard {
    margin: 10px auto;
    width: calc(100% - 30px);
    padding: 0 15px;
    border-radius: 15px;
    margin-bottom: 30px;
    box-sizing: border-box;
    box-shadow: 0 5px 20px 3px #0000000a;

    .function-item {
      width: 100%;
      height: 60px;
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      border-bottom: 1px solid #eeeeee;

      &:last-child {
        border-bottom: none;
      }

      .icon {
        width: 25px;
        height: 25px;
        margin-top: 2.5px;
        margin-right: 10px;

        img {
          width: 25px;
          height: 25px;
        }
      }

      .text {
        flex: 1;
        line-height: 30px;
        text-align: left;
      }

      .right {
        height: 20px;
        margin-top: 5px;
        margin-left: 5px;

        img {
          height: 20px;
        }
      }
    }
  }

  .exitCard {
    margin: 10px auto;
    width: calc(100% - 30px);
    padding: 0 15px;
    border-radius: 15px;
    box-sizing: border-box;
    box-shadow: 0 5px 20px 3px #0000000a;

    .exit {
      width: 100%;
      height: 60px;
      padding: 15px;
      box-sizing: border-box;
      display: flex;

      .icon {
        width: 25px;
        height: 25px;
        margin-top: 2.5px;
        margin-right: 10px;

        img {
          width: 25px;
          height: 25px;
        }
      }

      .text {
        flex: 1;
        line-height: 30px;
        text-align: left;
        color: #48c3ff;
      }

      .right {
        height: 20px;
        margin-top: 5px;
        margin-left: 5px;

        img {
          height: 20px;
        }
      }
    }
  }
}
</style>