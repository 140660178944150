<template>
  <div id="app">
    <transition name="fade">
      <keepAlive :include="keepAlive"> <router-view></router-view> </keepAlive
    ></transition>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  computed: {
    // 设置keep-alive缓存的页面
    keepAlive() {
      return this.$store.getters.keepAlive;
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* position: relative;
  z-index: 99999; */
}
.van-dialog__header{
  width: 80%;
  margin: 0 auto;
}
/* .van-toast.van-toast--middle.van-toast--loading {
  z-index: 999999;
} */

/* fade(过渡) */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* bounce(动画) */
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
