<template>
  <div class="voucherServices1">
    <van-nav-bar fixed title="档案入库"> </van-nav-bar>
    <van-dialog v-model="show" title="根据档案号建议放入以下位置：" show-cancel-button>
        <p>档案库：{{da_ku}}</p>
        <p>档案架：{{da_jia}}</p>
        <p>档案盒：{{da_he}}</p>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";

export default {
  name: "barcodeLists",
  data() {
    return {
      show: false,
      da_ku:'202',
      da_jia:'AJ-0001',
      da_he:'CW-9992'
     
    };
  },
  methods: {
    
  },
  created(){
      this.show = true
  }
};
</script>

<style lang="less" scoped >

.voucherServices1 {
  box-sizing: border-box;
  padding-top: 45px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

}
</style>