<template>
  <div class="voucherServices">
    <div class="header">
      <!-- <div class="close" @click="close">
        <van-icon name="cross" size="20px" />
      </div> -->
      九霄 · 全家桶
    </div>

    <div class="banner">
      <img class="bn" src="../assets/images/images/banner.jpg" alt="" />
      <img class="jqr" src="../assets/images/images/jqr.png" alt="" />

      <div class="dialogBox">
        <img src="../assets/images/images/dialogBox.png" alt="" />
        <div class="text">
          您好呀~我是全家桶<br />
          我可以帮您做实物档案入库与查询利用哦 O(∩_∩)O~
        </div>
      </div>
    </div>
    <div class="function">
      <div class="uploadCard tmgl" @click="toBarcode">
        <div class="text">条码管理</div>
        <div class="littleHint">去打印</div>
        <div class="toRightIcon">
          <img src="../assets/images/icon/toRight.png" alt="" />
        </div>
        <div class="icon">
          <img src="../assets/images/icon/upload.png" alt="" />
        </div>
      </div>
      <div class="searchCard dayj" @click="toTransferFile">
        <div class="text">档案文件移交</div>
        <div class="littleHint">去移交</div>
        <div class="toRightIcon">
          <img src="../assets/images/icon/toRight.png" alt="" />
        </div>
        <div class="icon">
          <img src="../assets/images/icon/shoucangjia.png" alt="" />
        </div>
      </div>
    </div>
    <div class="function">
      <div class="uploadCard" @click="toUpload">
        <div class="text">档案文件入库</div>
        <div class="littleHint">去入库</div>
        <div class="toRightIcon">
          <img src="../assets/images/icon/toRight.png" alt="" />
        </div>
        <div class="icon">
          <img src="../assets/images/icon/upload.png" alt="" />
        </div>
      </div>
      <div class="searchCard xnkf" @click="toSearch">
        <div class="text">档案查询与利用</div>
        <div class="littleHint">去搜索</div>
        <div class="toRightIcon">
          <img src="../assets/images/icon/toRight.png" alt="" />
        </div>
        <div class="icon">
          <img src="../assets/images/icon/search.png" alt="" />
        </div>
      </div>
    </div>
    <div class="function">
      <div class="uploadCard cxyly" @click="toXnkf">

        <div class="text">电子档案馆</div>
        <div class="littleHint">去查看</div>
        <div class="toRightIcon">
          <img src="../assets/images/icon/toRight.png" alt="" />
        </div>
        <div class="icon">
          <img src="../assets/images/icon/shoucangjia.png" alt="" />
        </div>

        
      </div>
      <div class="searchCard expectCard" @click="showExpectHint">
        <div class="icon">
          <img src="../assets/images/icon/expect2.png" alt="" />
        </div>
        <!-- <img class="text" src="../assets/images/icon/expect.png" alt="" /> -->
        <div class="text">更多功能, 敬请期待...</div>

        <!-- <div class="toRightIcon">
          <img src="../assets/images/icon/toRight.png" alt="" />
        </div> -->
      </div>
    </div>
    <div class="expect"></div>

    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      @select="onSelect"
    />
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import WX from "weixin-js-sdk";

export default {
  name: "VoucherServices",
  data() {
    return {
      show: false,
      actions: [
        {
          name: "电子档案",
        },
        {
          name: "实物档案",
        },
      ],
    };
  },
  methods: {
    close() {
      // Dialog.confirm({
      //   title: "温馨提示",
      //   message: "您确定需要退出登录吗？",
      //   confirmButtonColor: "#1989fa",
      // })
      //   .then(() => {
      //     sessionStorage.removeItem("userInfo");
      //     this.$router.push("/");
      //   })
      //   .catch(() => {
      //     // on cancel
      //   });
    },

    onSelect(item) {
      // 点击选项时默认不会关闭菜单，可以手动关闭
      let self = this;
      self.show = false;
      console.log(item);
      if (item.name == "电子档案") {
        this.show = false;
        Toast("敬请等待");
      }
      if (item.name == "实物档案") {
        this.show = false;
        this.$router.push("/SearcLists");
      }
    },

    toBarcode() {
      this.$router.push("/BarcodeLists");
    },
    toTransferFile() {
      // this.$router.push("/TransferFile'");
      // this.$router.push({
      //   path: "/TransferFile",
      //   query: {
      //     codeInfo: "KD0001",
      //   },
      // });
      let self = this;
      WX.scanQRCode({
        desc: "scanQRCode desc",
        needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
        success: function (res) {
          // 回调
          console.log(res);

          let codeInfo = res.resultStr;
          self.$router.push({
            path: "/TransferFile",
            query: {
              codeInfo: codeInfo,
            },
          });
        },
        error: function (res) {
          if (res.errMsg.indexOf("function_not_exist") > 0) {
            alert("版本过低请升级");
          }
        },
      });
    },

    // 跳转上传证件页面
    toUpload() {
      // this.$router.push({
      //   path: "/uploadArchives",
      //   query: {
      //     codeInfo: "KD001",
      //   },
      // });
      let self = this;
      WX.scanQRCode({
        desc: "scanQRCode desc",
        needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
        success: function (res) {
          // 回调
          console.log(res);

          let codeInfo = res.resultStr;
          self.$router.push({
            path: "/UploadArchives",
            query: {
              codeInfo: codeInfo,
            },
          });
        },
        error: function (res) {
          if (res.errMsg.indexOf("function_not_exist") > 0) {
            alert("版本过低请升级");
          }
        },
      });
    },

    // 跳转搜索页面
    toSearch() {
      // this.show = true;
      this.$router.push("/ChooseModule");
    },

    toXnkf() {
      this.$router.push("/Xnkf");
    },

    showExpectHint() {
      Toast("更多功能，敬请期待...");
    },
    getWeiXinJSAPISignature() {
      let self = this;
      let url = window.location.href;
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let fd = new FormData();
      fd.append("Url", url);
      self.$axios
        .post(
          "https://beyondsky.docuvix.com/peacepay/api/Home/GetSignature",
          fd,
          config
        )
        .then(function (response) {
          //成功
          console.log(response);
          let res = response.data;
          self.WXConfig(
            "wx27e60c547a5ab724",
            res.timestamp,
            res.noncestr,
            res.signature
          );
        })
        .catch(function (error) {
          //捕获失败
          // self.showLoading = false;
          alert(error);
        });
    },
    WXConfig(appId, timestamp, nonceStr, signature) {
      WX.config({
        debug: false,
        appId: appId,
        timestamp: timestamp,
        nonceStr: nonceStr,
        signature: signature,
        jsApiList: [
          "chooseImage",
          "uploadImage",
          "getLocalImgData",
          "previewImage",
          "getNetworkType",
          "checkJsApi",
          "scanQRCode",
        ],
      });
      // checkJsApi判断当前客户端版本是否支持指定JS接口，可以不需要调。
      WX.ready(function () {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });
      WX.error(function (res) {
        console.log(res);
        Dialog.alert({
          title: "温馨提示",
          message: JSON.stringify(res),
        }).then(() => {});
      });
      WX.checkJsApi({
        jsApiList: [
          "chooseImage",
          "uploadImage",
          "getLocalImgData",
          "previewImage",
          "getNetworkType",
          "checkJsApi",
          "scanQRCode",
        ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function (res) {
          console.log(res);
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        },
      });
    },
  },
  created() {
    this.getWeiXinJSAPISignature();
  },
};
</script>

<style lang="less" scoped >
@keyframes hide {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  23% {
    opacity: 1;
  }
  77% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.voucherServices {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .header {
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin-bottom: 10px;
    // color: #fff;
    // background-color: #1989fa;
    // background: linear-gradient(to right, #0689ff, #4c3dfc);
    position: relative;

    .close {
      height: 44px;
      padding-top: 4px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 15px;
    }
  }

  .banner {
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    position: relative;
    // margin-bottom: 20px;
    .bn {
      width: 100%;
      border-radius: 10px;
    }

    .jqr {
      width: 100px;
      position: absolute;
      bottom: 0;
      left: 15px;
    }

    .dialogBox {
      position: absolute;
      bottom: 60px;
      left: 85px;
      width: 150px;
      height: 90px;
      padding: 5px;
      text-align: left;
      font-size: 10px;
      box-sizing: border-box;
      animation: hide 10s linear 0s infinite alternate;

      .text {
        position: relative;
        line-height: 20px;
        z-index: 2;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .function {
    width: 100%;
    height: 130px;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    text-align: left;

    .uploadCard {
      flex: 1;
      margin-right: 20px;
      padding: 15px;
      background-image: linear-gradient(to right, #8d3fdd, #8d3fdd);
      // background: #ffc72a;
      border-radius: 10px;
      box-sizing: border-box;
      position: relative;

      .littleHint {
        color: #8d3fdd;
      }
    }

    .searchCard {
      flex: 1;
      background-image: linear-gradient(to right, #06bd9ec1, #06bd9e);
      // background: #06bd9e;
      border-radius: 10px;
      padding: 15px;
      box-sizing: border-box;
      position: relative;

      .littleHint {
        color: #06bd9e;
      }
    }

    .expectCard {
      background-image: linear-gradient(to right, #dcdee0, #dcdee0);
    }
    .cxyly {
      background-image: linear-gradient(to right, #dd3f64, #dd3f64);
      .littleHint {
        color: #dd3f64;
      }
    }
    .xnkf {
      background-image: linear-gradient(to right, #dd3f8d, #dd3f8d);
      .littleHint {
        color: #dd3f8d;
      }
    }
    .dayj {
      background-image: linear-gradient(to right, #3f7edd, #3f7edd);
      .littleHint {
        color: #3f7edd;
      }
    }
    .tmgl {
      background-image: linear-gradient(to right, #3fddb2, #3fddb2);
      .littleHint {
        color: #3fddb2;
      }
    }

    .text {
      line-height: 20px;
      color: #ffffff;
      // text-align: left;
      margin-bottom: 20px;
    }

    .littleHint {
      display: inline-block;
      font-size: 12px;
      line-height: 15px;
      background-color: #ffffff;
      text-align: center;
      padding: 2px 5px;
      border-radius: 5px;
    }

    .toRightIcon {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 15px;
      right: 15px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .icon {
      width: 40px;
      position: absolute;
      bottom: 5px;
      right: 15px;
      opacity: 0.3;

      img {
        width: 100%;
      }
    }
  }

  .expect {
    width: 100%;
    height: 115px;
    padding: 15px;
    padding-top: 0;
    box-sizing: border-box;
    position: relative;

    .expectCard {
      width: 100%;
      height: 100%;
      padding-top: 20px;
      padding-left: 80px;
      box-sizing: border-box;
      border-radius: 10px;
      background-image: linear-gradient(to right, #dcdee0, #dcdee0);
      // background-color: #ff6162;

      .text {
        line-height: 60px;
        font-size: 20px;
        // font-weight: bold;
        color: #ffffff;
        text-align: left;
        // font-style: oblique;
      }

      .icon {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 20px;
        left: 30px;
        opacity: 0.3;

        img {
          width: 100%;
          height: 100%;
        }
      }

      // .toRightIcon {
      //   width: 20px;
      //   height: 20px;
      //   position: absolute;
      //   top: 15px;
      //   right: 30px;

      //   img {
      //     width: 100%;
      //     height: 100%;
      //   }
      // }
    }
  }
}
</style>