import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Load from '../views/Load.vue'
import UploadArchives from '../views/UploadArchives.vue'
import SearcLists from '../views/SearcLists.vue'
import ArchivesDetail from '../views/ArchivesDetail.vue'
import ChooseModule from '../views/ChooseModule.vue'
import Xnkf from '../views/Xnkf.vue'
import Flists from '../views/Flists.vue'
import HeLists from '../views/HeLists.vue'
import FileLists from '../views/FileLists.vue'
import FileDetail from '../views/FileDetail.vue'
import BarcodeDetails from '../views/BarcodeDetails.vue'
import BarcodeLists from '../views/BarcodeLists.vue'
import TransferFile from '../views/TransferFile.vue'
import Dialog from '../views/Dialog.vue'
import Borrow from '../views/Borrow.vue'


Vue.use(VueRouter)

const routes = [{
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/uploadArchives',
        name: 'UploadArchives',
        component: UploadArchives
    },{
        path: '/searcLists',
        name: 'SearcLists',
        component: SearcLists
    },{
        path: '/archivesDetail',
        name: 'ArchivesDetail',
        component: ArchivesDetail
    },{
        path: '/archivesDetail',
        name: 'ArchivesDetail',
        component: ArchivesDetail
    },{
        path: '/chooseModule',
        name: 'ChooseModule',
        component: ChooseModule
    },{
        path: '/xnkf',
        name: 'Xnkf',
        component: Xnkf
    },{
        path: '/flists',
        name: 'Flists',
        component: Flists
    },{
        path: '/fileLists',
        name: 'FileLists',
        component: FileLists
    },{
        path: '/heLists',
        name: 'HeLists',
        component: HeLists
    },{
        path: '/fileDetail',
        name: 'FileDetail',
        component: FileDetail
    },{
        path: '/barcodeDetails',
        name: 'BarcodeDetails',
        component: BarcodeDetails
    },{
        path: '/barcodeLists',
        name: 'BarcodeLists',
        component: BarcodeLists
    },{
        path: '/transferFile',
        name: 'TransferFile',
        component: TransferFile
    },{
        path: '/dialog',
        name: 'Dialog',
        component: Dialog
    },{
        path: '/borrow',
        name: 'Borrow',
        component: Borrow
    }
    
    
]

const router = new VueRouter({
    routes
})

export default router