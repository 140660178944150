<template>
  <div class="home">
    <van-nav-bar fixed title="借用申请单" left-arrow @click-left="goBack" />

    <div class="content">
      <van-cell-group>
        <van-field
          v-model="startTime"
          label="借用开始时间"
          type="text"
          input-align="right"
          placeholder="请选择借用开始时间"
          rows="1"
          @click="show = true"
          autosize
        />
        <van-field
          v-model="endTime"
          label="借用结束时间"
          type="text"
          input-align="right"
          placeholder="请选择借用结束时间"
          rows="1"
          autosize
          @click="show1 = true"
        />
        <van-field
          v-model="remark"
          label="借用理由"
          type="textarea"
          input-align="right"
          placeholder="请输入借用理由"
          rows="2"
          autosize
        />
      </van-cell-group>
      <van-button class="btn" type="info" @click="goSubmit"
        >提交申请</van-button
      >
    </div>
    <van-popup v-model="show" position="bottom"
      ><van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择借用时间"
        @confirm="confirm"
        @cancel="show = false"
    /></van-popup>
    <van-popup v-model="show1" position="bottom"
      ><van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择借用时间"
        @confirm="confirm1"
        @cancel="show = false"
    /></van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";

export default {
  name: "UploadArchives",

  data() {
    return {
      show: false,
      show1: false,
      endTime: "",
      startTime: "",
      remark: "",
      currentDate: new Date(),
    };
  },
  methods: {
    goBack() {
      window.history.go(-1);
    },
    goSubmit() {
      Toast("借用成功！");
      setTimeout(function(){
         window.history.go(-1);
      },2000)
    },
    confirm() {
      this.show = false;
      this.startTime =
        this.currentDate.getFullYear() +
        "年" +
        (Number(this.currentDate.getMonth()) + 1) +
        "月" +
        this.currentDate.getDate() +
        "日 " +
        this.currentDate.getHours() +
        ":" +
        this.currentDate.getMinutes();
    },
    confirm1() {
      this.show1 = false;
      this.endTime =
        this.currentDate.getFullYear() +
        "年" +
        (Number(this.currentDate.getMonth()) + 1) +
        "月" +
        this.currentDate.getDate() +
        "日 " +
        this.currentDate.getHours() +
        ":" +
        this.currentDate.getMinutes();
    },
  },
  created() {},
};
</script>

<style lang="less" scoped >
.home {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
}
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  padding-top: 46px;
  position: relative;
}
.btn {
  width: 100%;
  position: absolute !important;
  bottom: 0;
  left: 0;
}
</style>