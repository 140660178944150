<template>
  <div class="home">


    <!-- <van-tabbar v-model="active">
      <van-tabbar-item name="voucherServices">
        <span>证小二</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="favorites">
        <span>收藏夹</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="me">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active : icon3.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar> -->


    <transition name="fade">
      <keep-alive>
        <VoucherServices v-if="active == 'voucherServices'" ></VoucherServices>

        <Favorites v-else-if="active == 'favorites'"></Favorites>

        <Me v-else-if="active == 'me'"></Me>
      </keep-alive>
    </transition>

    
  </div>
</template>

<script>
import VoucherServices from "../components/VoucherServices.vue";
import Favorites from "../components/Favorites.vue";
import Me from "../components/Me.vue";
import { Toast } from "vant";
export default {
  name: "Home",
  components: {
    VoucherServices,
    Favorites,
    Me,
  },
  data() {
    return {
      active: "voucherServices",
      userInfo: {},
      show: false,
      actions: [
        {
          name: '选择拍照'
        },
        {
          name: '选择相册'
        }
      ],

      icon1: {
        active: require("../assets/images/icon/zhengjian-active.png"),
        inactive: require("../assets/images/icon/zhengjian.png"),
      },
      icon2: {
        active: require("../assets/images/icon/shoucangjia-active.png"),
        inactive: require("../assets/images/icon/shoucangjia.png"),
      },
      icon3: {
        active: require("../assets/images/icon/wo-active.png"),
        inactive: require("../assets/images/icon/wo.png"),
      },
    };
  },
  methods: {
    getCode(){
      var code = sessionStorage.getItem('code')
      console.log(code)
      if (code == '401') {
        this.$router.push("/login");
      }
    },
    
    // 获取用户信息(接口对接(暂时已完成))
    getUserInfo() {
      this.$axios
        .post("https://emonitor.docuvix.com/lmuserservice/api/v1/GetPersonalCenter")
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.userInfo = res.data.data[0];

            sessionStorage.setItem("userInfo", JSON.parse(this.userInfo));
            Toast("登录成功,欢迎使用证小二");
          } else {
            Toast("用户数据获取失败," + res.data.msg);
          }
        })
        .catch((err) => {});
    },

    //获取类别
    GetAllDiscernCategory() {
      this.$axios
        .post("https://emonitor.docuvix.com/lmuserservice/api/v1/GetAllDiscernCategory")
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            // Toast("成功！");
            var CategoryData = res.data.data
            sessionStorage.setItem('CategoryData',JSON.stringify(CategoryData))

          } else {
            Toast("类别数据获取失败," + res.data.msg);
          }
        })
        .catch((err) => {});
    },
  },
  created() {
    //判断身份是否失效
    // this.getCode()
    // this.GetAllDiscernCategory()
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        from.fullPath == "/login" ||
        from.fullPath == "/faceIdentify" ||
        from.fullPath == "/register"
      ) {
        vm.getUserInfo();
      }
      vm.$store.commit("setKeepAlive", ["Home"]);
    });
  },
};
</script>

<style lang="less" scoped > 
/* fade(过渡) */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>