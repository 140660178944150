<template>
  <div class="favorites">
    <div class="header">
      收藏夹
      <div class="filter" @click="showFilter = true">
        <van-icon name="filter-o" size="20px" />
      </div>
    </div>
    <div class="favoritesContent" v-if="!isFilter">
      <div class="favoritesNum">共收藏了{{ collections.length }}张证照</div>
      <div class="voucherInfo">
        <div
          class="item"
          v-for="(item, index) in collections"
          :key="item.id"
          @click="toVoucherDetail(item.contentid)"
        >
          <div class="item-num">{{ index + 1 }}</div>
          <div class="item-content">
            <div class="content-type">{{ item.categotyname }}</div>
            <div class="content-company content-middle">
              保管公司：{{ item.companyname }}
            </div>
            <!-- <div
              class="content-belong content-middle"
              v-if="item.type == '身份证'"
            >
              所属人：{{ item.belong }}
            </div>
            <div
              class="content-bank content-middle"
              v-if="item.type == '银行卡'"
            >
              银行：{{ item.bank }}
            </div> -->
            <div class="content-remark">证照备注：{{ item.remarks }}</div>
          </div>
          <div
            class="item-star"
            @click.stop="cancelCollect(index, false, item.categoryid)"
          >
            <img src="../assets/images/icon/star-active.png" alt="" />
          </div>
        </div>
        <!-- <div class="bottomHint">已经到底了哦</div> -->
      </div>
      <div class="bottomHint">已经到底了哦</div>
    </div>

    <div class="filterResult" v-else>
      <div class="filterTitle">
        筛选结果
        <div class="closeFilter" @click="isFilter = false">
          <van-icon name="cross" size="20px" color="#ffffff" />
        </div>
      </div>
      <div class="voucherInfo">
        <div
          class="item"
          v-for="(item, index) in filterCollections"
          :key="item.id"
          @click="toVoucherDetail(item.contentid)"
        >
          <div class="item-num">{{ index + 1 }}</div>
          <div class="item-content">
            <div class="content-type">{{ item.type }}</div>
            <div class="content-company content-middle">
              保管公司：{{ item.company }}
            </div>
            <!-- <div
              class="content-belong content-middle"
              v-if="item.type == '身份证'"
            >
              所属人：{{ item.belong }}
            </div>
            <div
              class="content-bank content-middle"
              v-else-if="item.type == '银行卡'"
            >
              银行：{{ item.bank }}
            </div> -->
            <div class="content-remark">证照备注：{{ item.remark }}</div>
          </div>
          <div class="item-star">
            <img
              src="../assets/images/icon/star-active.png"
              alt=""
              @click.stop="cancelCollect(index, true, item.categoryid)"
            />
          </div>
        </div>
      </div>
      <div class="bottomHint">已经到底了哦</div>
    </div>

    <van-popup
      v-model="showFilter"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="condition">
        <div class="conditionValue">
          <div class="title">日期</div>
          <div class="condition-item">
            <div class="wrap">
              <van-icon name="arrow-down" />
              <van-field
                readonly
                clickable
                name="datetimePicker"
                :value="startDate"
                placeholder="选择日期"
                @click="showPicker = true"
              />
            </div>
            <div class="line">—</div>
            <div class="wrap">
              <van-icon name="arrow-down" />
              <van-field
                readonly
                clickable
                name="datetimePicker"
                :value="endDate"
                placeholder="选择日期"
                @click="showPicker2 = true"
              />
            </div>
          </div>
          <div class="title">类型</div>
          <div class="condition-item type">
            <van-field
              readonly
              clickable
              name="picker"
              :value="voucherType"
              label="证照类型"
              placeholder="点击选择证照类型"
              @click="showPicker3 = true"
            />
            <van-popup v-model="showPicker3" position="bottom">
              <van-picker
                show-toolbar
                :columns="columns"
                @confirm="onConfirm3"
                @cancel="showPicker3 = false"
              />
            </van-popup>
          </div>
        </div>

        <div class="conditionButton">
          <button class="cancel" @click="cancel">取消</button>
          <button class="filter" @click="filter">筛选</button>
        </div>
      </div>

      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          cancel-button-text="清空"
          title="选择年月日"
          v-model="currentDate"
          type="date"
          @confirm="onConfirm"
          @cancel="clearDate1"
        />
      </van-popup>

      <van-popup v-model="showPicker2" position="bottom">
        <van-datetime-picker
          cancel-button-text="清空"
          title="选择年月日"
          v-model="currentDate2"
          type="date"
          @confirm="onConfirm2"
          @cancel="clearDate2"
        />
      </van-popup>
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import Vue from "vue";

export default {
  name: "Favorites",
  data() {
    return {
      showFilter: false,
      showPicker: false,
      showPicker2: false,
      showPicker3: false,
      isFilter: false,
      startDate: null,
      endDate: null,
      currentDate: new Date(),
      currentDate2: new Date(),
      voucherType: null,
      collections: [],
      filterCollections: [],
      columns: ["营业执照", "身份证", "银行卡"],
    };
  },
  methods: {
    toVoucherDetail(ID) {
      this.$router.push({
        path: "/voucherDetail",
        query: {
          categoryid: ID,
        },
      });
    },

    // 获取收藏夹数据 (获取收藏夹数据接口对接)
    getFavoritesData() {
      Toast.loading({
        message: "数据加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });

      var args = {
        sort: "createtime",
        dir: "desc",
        pageIndex: 1,
        pageSize: 100000,
      };

      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      this.$axios
        .post(
          "https://emonitor.docuvix.com/lmuserservice/api/v1/GetUserDiscernCollection",
          JSON.stringify(args),
          config
        )
        .then((res) => {
          console.log(res);
          Toast.clear();
          if (res.data.success) {
            Toast("获取列表成功！");
            this.collections = res.data.data.result;
            for (let index = 0; index < res.data.data.result.length; index++) {
              const element = res.data.data.result[index];
              Vue.set(element, "isCollect", true);
            }
          } else {
            Toast("获取列表失败！");
          }
        })
        .catch((err) => {});
    },

    // 取消收藏 （取消收藏接口对接）
    cancelCollect(index, isFilter, ID) {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定要取消收藏该证照吗？",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          let self = this;
          let args = {
            contentid: ID,
          };

          let config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          self.$axios
            .post(
              "https://emonitor.docuvix.com/lmuserservice/api/v1/CancelUserDiscernCollection",
              JSON.stringify(args),
              config
            )
            .then((res) => {
              console.log(res);
              if (res.data.success) {
                // Toast("成功！");
                Toast("取消收藏成功");
                if (isFilter) {
                  this.filterCollections.splice(index, 1);
                } else {
                  this.collections.splice(index, 1);
                }
              } else {
                Toast("取消收藏失败！," + res.data.msg);
              }
            })
            .catch((err) => {});
        })
        .catch(() => {
          // on cancel
        });
    },

    // 开始日期选择器确定
    onConfirm(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      this.startDate = year + "-" + month + "-" + day;
      this.showPicker = false;
    },

    // 结束日期选择器确定
    onConfirm2(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      this.endDate = year + "-" + month + "-" + day;
      this.showPicker2 = false;
    },

    // 清空开始日期选择器
    clearDate1() {
      this.startDate = null;
      this.showPicker = false;
    },

    // 清空结束日期选择器
    clearDate2() {
      this.endDate = null;
      this.showPicker2 = false;
    },

    onConfirm3(value) {
      this.voucherType = value;
      this.showPicker3 = false;
    },

    // 取消筛选
    cancel() {
      this.showFilter = false;
    },

    // 筛选 (收藏夹筛选接口对接)
    filter() {
      Toast.loading({
        message: "证照筛选中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });

      // 以下为效果
      setTimeout(() => {
        this.showFilter = false;
        this.isFilter = true;
        Toast("筛选完成");
      }, 2000);
    },
  },
  created() {
    this.getFavoritesData();
  },
};
</script>

<style lang="less" scoped >
.favorites {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 44px 0;
  text-align: left;
  overflow-x: hidden;
  overflow-y: auto;

  .header {
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;

    .close {
      height: 44px;
      padding-top: 4px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 15px;
    }

    .filter {
      height: 44px;
      padding-top: 4px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      right: 15px;
    }

    // .edit {
    //   height: 44px;
    //   padding-top: 4px;
    //   box-sizing: border-box;
    //   position: absolute;
    //   top: 0;
    //   right: 15px;
    // }
  }

  .favoritesContent {
    width: 100%;
    min-height: 100%;
    background-color: #f5f5f5;
    // padding-bottom: 15px;

    .favoritesNum {
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      padding: 0 15px;
      box-sizing: border-box;
      color: #ffffff;
      background-color: #ff976a;
    }

    .voucherInfo {
      width: 100%;
      padding-top: 15px;
      box-sizing: border-box;
      background-color: #f5f5f5;
    }
  }

  .filterResult {
    width: 100%;
    min-height: 100%;
    background-color: #f5f5f5;
    .filterTitle {
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      padding: 0 15px;
      box-sizing: border-box;
      color: #ffffff;
      background-color: #ff6162;
      position: relative;

      .closeFilter {
        position: absolute;
        top: 0;
        right: 15px;
        width: 20px;
        height: 30px;
        line-height: 30px;
        padding-top: 4px;
      }
    }

    .voucherInfo {
      width: 100%;
      padding-top: 15px;
      box-sizing: border-box;
      background-color: #f5f5f5;
    }
  }

  .item {
    width: 100%;
    min-height: 110px;
    padding: 10px 15px;
    box-sizing: border-box;
    background-color: #ffffff;
    // margin-bottom: 10px;
    border-bottom: 10px solid #f5f5f5;
    display: flex;

    .item-num {
      width: 40px;
      font-size: 20px;
      // font-weight: bold;
      text-align: left;
      line-height: 80px;
      color: #999999;
    }

    .item-content {
      flex: 1;

      .content-type {
        margin-bottom: 14px;
        color: #333333;
      }

      .content-middle {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
        color: #666666;
      }

      .content-remark {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
        color: #666666;
      }
    }

    .item-star {
      width: 40px;

      img {
        width: 25px;
        height: 25px;
        margin: 25px 0 25px 15px;
      }
    }
  }

  .bottomHint {
    font-size: 14px;
    text-align: center;
    padding: 5px 0 25px 0;
    color: #999999;
    // background-color: #f5f5f5;
  }

  .condition {
    width: 100%;
    height: 100%;
    background-color: #f6f7f9;
    box-sizing: border-box;

    .conditionValue {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
    }

    .title {
      font-size: 16px;
      margin-bottom: 15px;
      text-align: left;
    }

    .condition-item {
      width: 100%;
      height: 44px;
      margin-bottom: 20px;
      font-size: 14px;
      display: flex;

      .wrap {
        flex: 1;
        position: relative;

        // &:first-child {
        //   margin-right: 20px;
        // }

        .van-icon-arrow-down {
          font-size: 16px;
          position: absolute;
          top: 14px;
          right: 14px;
          z-index: 10;
          color: #aaaaaa;
        }
      }

      .line {
        margin: 0 10px;
        line-height: 44px;
      }

      .option {
        flex: 1;
        height: 30px;
        color: #2c3e50;
        line-height: 30px;
        border-radius: 2px;
        background-color: #ffffff;
        border: 1px solid #ebedf0;
        text-align: center;
        transition: all 0.3s;

        &:nth-child(2) {
          margin: 0 15px;
        }

        &.active {
          color: #ffffff;
          background-color: #1989fa;
          border: 1px solid #1989fa;
        }
      }
    }

    .type-item {
      width: 100%;
      margin-bottom: 20px;
      text-align: left;

      .typeOption {
        line-height: 30px;
        border-radius: 2px;
        color: #2c3e50;
        background-color: #ffffff;
        border: 1px solid #ebedf0;
        text-align: center;
        transition: all 0.3s;
        line-height: 20px;
        padding: 5px 10px;
        font-size: 14px;
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 10px;

        &.active {
          color: #ffffff;
          background-color: #1989fa;
          border: 1px solid #1989fa;
        }
      }
    }

    .van-cell.van-field {
      width: 100%;
      height: 44px;
      padding-right: 50px;
      box-shadow: 0 0 1px 1px #eeeeee;
    }

    .conditionButton {
      width: 100%;
      height: 40px;
      line-height: 40px;
      position: absolute;
      bottom: 0;
      left: 0;

      .cancel {
        display: inline-block;
        width: 50%;
        border: none;
        outline: none;
        vertical-align: middle;
        border-top: 1px solid #dddddd;
      }

      .filter {
        display: inline-block;
        width: 50%;
        background-color: #1989fa;
        color: #ffffff;
        border: none;
        outline: none;
        vertical-align: middle;
        border-top: 1px solid #dddddd;
      }
    }
  }

  .condition .van-cell.van-field {
    padding-right: 16px;
  }

  .type .van-cell.van-field .van-field__control {
    text-align: right;
  }
}
</style>