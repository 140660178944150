<template>
  <div class="load">
    <div class="header">
      <img src="../assets/images/logo/logo1.png" alt="" />
      <img src="../assets/images/logo/logo2.png" alt="" />
      <!-- <div class="registerText" @click="toRegister">注册</div> -->
    </div>

    <!-- <div class="bg"></div> -->

    <div class="logo">
      <!-- <img src="../assets/images/logo/logo.png" alt="" />
      <img src="../assets/images/images/copyWriting.png" alt="" /> -->
      <img src="../assets/images/logo/logo3.png" alt="" />
    </div>

    <div class="select">
      <!-- <button class="option" @click="toHome">立即体验</button> -->
      <button class="option" @click="toLogin">账号登录</button>
      <button class="option" @click="toFaceLogin">人脸登录</button>
      <button class="option" @click="toRegister">用户注册</button>
    </div>

    <div class="copyright">
      Copyright © 2020 docuvix , All Rights Reserved <br />
      “X-SMART” “内容云·九霄” “证小二”<br />
      系列产品均由道可维斯版权所有
    </div>

    <van-overlay :show="showOtherBrowser" @click="showOtherBrowser = false">
      <div class="wrapper" @click.stop>
        <div class="browserrGuidance">
          <div class="arrows">
            <img src="../assets/images/images/rightTopArrows.png" alt="" />
          </div>

          <div class="explain">
            <div class="explainItem">
              <div class="num">1</div>
              <div class="content">
                <span>点击右上角</span>
                <div class="button first">
                  <img src="../assets/images/icon/more.png" alt="" />
                </div>
                <span>按钮</span>
              </div>
            </div>
            <div class="explainItem">
              <div class="num">2</div>
              <div class="content">
                <span>选择</span>
                <div class="button second">
                  <img src="../assets/images/icon/safari.png" alt="" />
                  <span>在Safari中打开</span>
                </div>
              </div>
            </div>

            <div class="explainItem">
              <div class="num">3</div>
              <div class="content">
                <span>点击</span>
                <div class="button third">人脸登录</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  name: "Load",
  data() {
    return {
      phoneModel: null,
      showOtherBrowser: false,
    };
  },
  methods: {
    // 跳转密码登录
    toLogin() {
      this.$router.push("/login");
    },

    // 开启人脸识别登录
    toFaceLogin() {
      console.log(this.phoneModel);
      if (this.phoneModel == "IOS WeChat") {
        this.showOtherBrowser = true;
        // this.$router.push("/iosFaceIdentify");
      } else if (this.phoneModel == "IOS") {
        // this.$router.push("/iosFaceIdentify");
        this.$router.push("/faceIdentify");
      } else if (this.phoneModel == "Android") {
        this.$router.push("/faceIdentify");
      } else if (this.phoneModel == "Android WeChat") {
        console.log("xxxx")
        this.$router.push("/faceIdentify");
      }

      // this.$router.push("/iosFaceIdentify");
    },

    // 跳转注册页面
    toRegister() {
      this.$router.push("/register");
    },

    // 跳转首页
    toHome() {
      this.$router.push("/home");
    },

    getPhoneModel() {
      let ua = navigator.userAgent.toLowerCase();
      //Android终端
      let isAndroid = ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1; //Ios终端
      let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        if (isWeixinBrowser()) {
          // return "WeChat";
          this.phoneModel = "IOS WeChat";
        } else {
          // return "IOS";
          this.phoneModel = "IOS";
        }
      } else if (/(Android)/i.test(navigator.userAgent)) {
        // return "Android";
        if (isWeixinBrowser()) {
          // return "WeChat";
          this.phoneModel = "Android WeChat";
        } else {
          // return "IOS";
          this.phoneModel = "Android";
        }
      }

      sessionStorage.setItem("phoneModel", this.phoneModel);

      function isWeixinBrowser() {
        return /micromessenger/.test(ua) ? true : false;
      }
    },
  },

  created() {
    this.getPhoneModel();
  },
};
</script>

<style lang="less" scoped >
.load {
  width: 100vw;
  height: 100vh;
  padding-top: 105px;
  box-sizing: border-box;
  background-image: url(../assets/images/bg/bg1.png);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;

  .header {
    width: 100%;
    height: 44px;
    padding-top: 10px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    text-align: center;

    img {
      // vertical-align: middle;

      &:first-child {
        height: 24px;
        margin-right: 10px;
      }

      &:last-child {
        height: 20px;
      }
    }

    // .registerText {
    //   font-size: 16px;
    //   color: #ffffff;
    //   position: absolute;
    //   top: 50%;
    //   right: 10px;
    //   transform: translateY(-50%);
    // }
  }

  .logo {
    width: 135px;
    margin: 0 auto;

    img {
      width: 100%;
      margin-bottom: 5px;
    }

    .text {
      font-size: 16px;
      color: #ffffff;
    }
  }

  .select {
    width: 100vw;
    height: 150px;
    padding: 0 30px;
    box-sizing: border-box;
    position: absolute;
    bottom: 89px;
    left: 0;

    .option {
      display: block;
      width: 100%;
      height: 41px;
      border-radius: 20px;
      color: #ffffff;
      // background: linear-gradient(to bottom, #19d1faa3, #ffffff28);
      background-color: #1989face;
      text-align: center;
      margin-bottom: 10px;
      border: none;
      outline: none;
    }
  }

  .copyright {
    width: 100%;
    line-height: 18px;
    color: #cccccc;
    font-size: 9px;
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 0;
  }

  .browserrGuidance {
    width: 100%;
    height: 100%;

    .arrows {
      width: 100%;
      height: 140px;
      text-align: right;
      padding: 20px;
      padding-bottom: 10px;
      box-sizing: border-box;

      img {
        height: 100%;
      }
    }

    .explain {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      .explainItem {
        width: 100%;
        margin-bottom: 30px;
        line-height: 30px;
        // font-size: 20px;
        color: #ffffff;
        text-align: left;

        .num {
          width: 30px;
          height: 30px;
          line-height: 30px;
          margin-right: 10px;
          text-align: center;
          border-radius: 50%;
          background-color: #ff6700;
          vertical-align: middle;
          display: inline-block;
        }

        .content {
          height: 30px;
          line-height: 30px;
          vertical-align: middle;
          display: inline-block;
          letter-spacing: 3px;

          span {
            vertical-align: middle;
          }

          .button {
            height: 30px;
            line-height: 30px;
            display: inline-block;
            vertical-align: middle;
            padding: 0 5px;
            margin: 0 10px;
            text-align: center;
            border-radius: 5px;
            border-top: 1px solid #7d7d7d;
            background: linear-gradient(to bottom, #515151, #1d1d1d);
            font-size: 16px;

            span {
              height: 30px;
              display: inline-block;
              vertical-align: top;
            }

            img {
              width: 25px;
              height: 25px;
              margin-top: 2.5px;
              vertical-align: top;
              // margin-right: 5px;
              // transform: translateY(-2px);
            }

            &.second {
              img {
                margin-right: 10px;
                // transform: translateY(-3px);
              }
            }

            &.third {
              width: 140px;
              border-radius: 15px;
              color: #ffffff;
              background: #1989face;
              text-align: center;
              border: none;
            }
          }
        }
      }
    }
  }
}
</style>