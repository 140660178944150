import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        keepAlive: []
    },
    mutations: {
        // 设置keep-alive缓存的页面
        setKeepAlive: (state, keepAlive) => {
            console.log("xxxxxx");
            state.keepAlive = keepAlive;
        }
    },
    getters: {
        keepAlive: state => state.keepAlive
    },
    actions: {},
    modules: {}
})