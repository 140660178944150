<template>
  <div class="voucherServices1">
    <van-nav-bar fixed title="档案查询与利用" left-arrow @click-left="goBack">
    </van-nav-bar>
    <div class="serch">
      <van-field
        v-model="value"
        left-icon="search-o"
        placeholder="请输入关键词搜索"
      />
      <div class="mycontent">
        <van-tabs v-model="active" color="#1989fa">
          <van-tab title="最新">
            <div
              class="myBlock"
              v-for="(item, index) in archivesLists"
              :key="index"
              @click="goDetail(item)"
            >
              <div class="blockLeft">
                <img ref="addref" src="../assets/images/icon/zhengjian.png" />
              </div>
              <div class="blockRight">
                <p class="zhuti">{{ item.zhuti }}</p>
                <p>{{ item.dnk }}库{{ item.daj }}架{{ item.dah }}盒</p>
              </div>
            </div>
          </van-tab>
          <van-tab title="常用">
            <div
              class="myBlock"
              v-for="(item, index) in archivesLists1"
              :key="index"
              @click="goDetail(item)"
            >
              <div class="blockLeft">
                <img ref="addref" src="../assets/images/icon/zhengjian.png" />
              </div>
              <div class="blockRight">
                <p class="zhuti">{{ item.zhuti }}</p>
                <p>{{ item.dnk }}库{{ item.daj }}架{{ item.dah }}盒</p>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";

export default {
  name: "chooseModule",
  data() {
    return {
      show: false,
      active: "1",
      value:'',
      archivesLists: [
        {
          zhuti: "公司2021年年度计划",
          dnk: "DA-2021",
          daj: "AJ-0001",
          dah: "CW-9992",
        },{
          zhuti: "2021年-2月股东协议",
          dnk: "DA-2021",
          daj: "AJ-0001",
          dah: "CW-9992",
        },{
          zhuti: "BXD202010002银行回单",
          dnk: "DA-2021",
          daj: "AJ-0001",
          dah: "CW-9992",
        },{
          zhuti: "采购沙发收货单",
          dnk: "DA-2021",
          daj: "AJ-0001",
          dah: "CW-9992",
        },
      ],
      archivesLists1: [
        {
          zhuti: "公司营业执照",
          dnk: "DA-2021",
          daj: "AJ-0001",
          dah: "CW-9992",
        }
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.push("/home");
    },
    goDetail(item){
      this.$router.push({
        path:'/FileDetail',
        query:{
          title:item.zhuti
        }
      });
    },
    // 跳转上传证件页面
    toUpload() {
      this.$router.push("/SearcLists");
    },

    // 跳转搜索页面
    toSearch() {
      // this.show = true;
      this.$router.push("/ChooseModule");
    },

    toXnkf() {
      this.$router.push("/searchVoucher");
    },

    showExpectHint() {
      Toast("更多功能，敬请期待...");
    },
  },
};
</script>

<style lang="less" scoped >
.serch {
  width: 100%;
  height: 40px;
  // line-height: 40px;
  padding: 0 20px;
  box-sizing: border-box;

  .van-cell {
    background-color: #f4f4f4;
  }
}
.myBlock {
  width: 100%;
  height: 80px;
  margin-top: 5px;
  border-bottom: 2px solid #eee;
  .blockLeft {
    width: 80px;
    height: 100%;
    float: left;
    img {
      height: 80%;
      vertical-align: text-top;
      margin-top: 7px;
    }
  }
  .blockRight {
    width: 100%;
    // float: right;
    padding-left: 80px;
    box-sizing: border-box;
    height: 100%;
    .zhuti {
      font-size: 16px;
      font-weight: 900;
      line-height: 50px;
    }
    p {
      margin: 0;
      padding: 0 20px;
      font-size: 10px;
      text-align: left;
      line-height: 20px;
    }
  }
}

.voucherServices1 {
  box-sizing: border-box;
  padding-top: 45px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .header {
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin-bottom: 10px;
    // color: #fff;
    // background-color: #1989fa;
    // background: linear-gradient(to right, #0689ff, #4c3dfc);
    position: relative;

    .close {
      height: 44px;
      padding-top: 4px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 15px;
    }
  }

  .function1 {
    width: 100%;
    height: 130px;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    text-align: left;

    .uploadCard1 {
      flex: 1;
      padding: 15px;
      margin-right: 0;
      background-image: linear-gradient(to right, #ff652a75, #3f2affd5);
      border-radius: 10px;
      box-sizing: border-box;
      position: relative;
    }
    .text1 {
      width: 40%;
      float: left;
      height: 100%;
      margin: 0;
      border-radius: 50%;
      img {
        height: 100%;
      }
    }
    .littleHint1 {
      display: inline-block;
      font-size: 24px;
      line-height: 76px;
      background: none;
      color: #fff !important;
      text-align: center;
      padding: 2px 5px;
      border-radius: 5px;
    }
  }
}
</style>