<template>
  <div class="home">
    <van-nav-bar fixed title="实物档案详情" left-arrow @click-left="goBack" />
    <div class="content">
      <van-cell-group>
        <van-field
          v-model="PhysicalArchives_Code"
          label="实物档案编号"
          type="text"
          input-align="right"
          placeholder="请输入实物档案编号"
          rows="1"
          autosize
        />
        <van-field
          v-model="PhysicalArchives_Theme"
          label="主题"
          type="text"
          input-align="right"
          placeholder="请输入主题"
          rows="1"
          autosize
        />
        <van-field
          v-model="PhysicalArchives_Custodian"
          label="保管人"
          type="text"
          input-align="right"
          placeholder="请输入保管人"
          rows="1"
          autosize
        />
        <van-field
          v-model="PhysicalArchives_Room"
          label="档案库"
          type="text"
          input-align="right"
          placeholder="请输入档案库"
          rows="1"
          autosize
        />
        <van-field
          v-model="PhysicalArchives_Shelf"
          label="档案架"
          type="text"
          input-align="right"
          placeholder="请输入档案架"
          rows="1"
          autosize
        />
        <van-field
          v-model="PhysicalArchives_Box"
          label="档案盒"
          type="text"
          input-align="right"
          placeholder="请输入档案盒"
          rows="1"
          autosize
        />
        <van-field
          v-model="PhysicalArchives_StorageTime"
          label="保管时长"
          type="text"
          input-align="right"
          placeholder="请输入保管时长"
          rows="1"
          autosize
        />
        <van-field
          v-model="PhysicalArchives_Remark"
          label="备注"
          type="textarea"
          input-align="right"
          placeholder="请输入备注"
          rows="2"
          autosize
        />
      </van-cell-group>


      <div class="myImg">
        <img  src="../assets/images/images/businessLicense.jpg" />
      </div>

      <div class="btns">
        <van-button class="btn" type="info" @click="goSubmit">借用历史</van-button>
        <van-button class="btn" type="warning" @click="goSubmit">调拨</van-button>
        <van-button class="btn" type="danger" @click="goSubmit">销毁</van-button>
      </div>
      
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "archivesDetail",

  data() {
    return {
      PhysicalArchives_Code: "DX202103081200",
      PhysicalArchives_Theme: "公司营业执照",
      PhysicalArchives_Custodian: "张三",
      PhysicalArchives_Room: "202库",
      PhysicalArchives_Shelf: "J-002",
      PhysicalArchives_Box: "DAK-002",
      PhysicalArchives_StorageTime: "5年",
      PhysicalArchives_Remark: "暂无",
    };
  },
  methods: {
    goBack() {
      window.history.go(-1);
    },
    getCode() {
      var code = sessionStorage.getItem("code");
      console.log(code);
      if (code == "401") {
        this.$router.push("/login");
      }
    },
    goSubmit() {
      Toast("敬请等待");
    },
  },
  created() {
    //判断身份是否失效
    // this.getCode()
    // this.GetAllDiscernCategory()
    let jsonList = this.$route.query.jsonList;
    // if (jsonList != "") {
    //   jsonList = JSON.parse(jsonList);
    //   this.PhysicalArchives_Code = jsonList.PhysicalArchives_Code;
    //   this.PhysicalArchives_Theme = jsonList.PhysicalArchives_Theme;
    //   this.PhysicalArchives_Custodian = jsonList.PhysicalArchives_Custodian;
    //   this.PhysicalArchives_Room = jsonList.PhysicalArchives_Room;
    //   this.PhysicalArchives_Shelf = jsonList.PhysicalArchives_Shelf;
    //   this.PhysicalArchives_Box = jsonList.PhysicalArchives_Box;
    //   this.PhysicalArchives_StorageTime = jsonList.PhysicalArchives_StorageTime;
    //   this.PhysicalArchives_Remark = jsonList.PhysicalArchives_Remark;
    // }
  },
};
</script>

<style lang="less" scoped >
.myImg{
  width: 100px;
  img{
    width: 100%;
  }
}
.home {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
}
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  padding-top: 46px;
  position: relative;
}
.btns {
  width: 100%;
  position: absolute !important;
  bottom: 0;
  left: 0;
  .btn{
    width: 33%;
  }
}
</style>