<template>
  <div class="voucherServices1">
    <van-nav-bar fixed :title="title" left-arrow @click-left="goBack">
    </van-nav-bar>
    <div class="codeLists">
      <van-field
        v-model="count"
        name="本次打印数量"
        label="打印数量："
        placeholder="请输入本次打印数量"
      />
      <div class="btnMy">
      <van-button @click="shengcheng" block type="info">立即生成</van-button>
    </div>
    <div class="border"></div>
      <div class="oneCode" v-for="(item, index) in codeLists" :key="index">
        <div class="oneCodeLfet">
          <img :src="item.src" alt="">
          <!-- <p>{{ item.juanzhong }}</p> -->
        </div>
        <!-- <div class="rightIcon">
          <van-button @click="tips" size="small" type="info"
            >立即打印</van-button
          >
        </div> -->
      </div>
      <van-button class="btn" type="info" @click="goSubmit"
        >全部打印</van-button
      >
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";

export default {
  name: "barcodeDetails",
  data() {
    return {
      show: false,
      title: "",
      count:'1',
      thisCode: "340-AJ001-00004",
      codeLists: [
      ],
    };
  },
  created() {
    this.title = this.$route.query.title;
  },
  methods: {
    shengcheng(){
      let json = [
        {
          juanzhong: "340-AJ001-00006",
          anjuan: "KD001",
          src:require('../assets/images/images/1wm1.png')
        },
        {
          juanzhong: "340-AJ001-00008",
          anjuan: "KD003",
          src:require('../assets/images/images/1wm2.png')
        },
        
      ]
      this.codeLists = json
    },
    goSubmit(){ 
        Toast("打印成功！");
        setTimeout(() => {
          window.history.go(-1);
        }, 2000);
    },
    tips() {
      Toast("请先连接打印机！");
    },
    goBack() {
      window.history.go(-1);
    },
    goDetail(item) {
      console.log(item);
      this.$router.push({
        path: "/BarcodeDetails",
        query: {
          title: item.anjuan,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped >
.codeLists {
  height: auto;
  margin-top: 5px;
  padding: 0 20px;
  box-sizing: border-box;
  .oneCode {
    height: 56px;
    border-bottom: 2px solid #eee;
    .oneCodeLfet {
      float: left;
      width: 100%;
      margin-top: 15px;
      img{
        width: 100%;
      }
      p {
        margin: 0;
        text-align: left;
        font-size: 20px;
        flex-wrap: 900;
        line-height: 56px;
      }
    }
    // .rightIcon {
    //   float: right;
    //   height: 56px;
    //   line-height: 56px;
    // }
  }
}

.thisCode {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 0 20px;
  border-bottom: 2px solid #eee;
  .codeLeft {
    float: left;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-weight: 900;
    width: 40%;
  }
  .codeVaule {
    float: left;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-weight: 900;
  }
}
.btnMy {
  padding: 10px 20px;
  box-sizing: border-box;
}
.border{
  width: 100%;
  height: 6px;
  background-color: #f4f4f4;
}
.voucherServices1 {
  box-sizing: border-box;
  padding-top: 45px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.btn {
  width: 100%;
  position: absolute !important;
  bottom: 0;
  left: 0;
}
</style>