<template>
  <div class="home">
    <van-nav-bar fixed title="档案查询与利用" left-arrow @click-left="goBack">
      <template #right>
        <van-icon @click="openSearch" name="search" size="18" />
      </template>
    </van-nav-bar>
    <div class="searchLists">
      <ul>
        <li v-for="(item, index) in searchLists" :key="index">
          <van-cell
            @click="goDetail(item)"
            :title="item.PhysicalArchives_Code"
            is-link
            :value="item.PhysicalArchives_RegistrationTime"
          />
          <div class="boder"></div>
        </li>
        <p class="tips" v-show="searchLists.length == 0">暂无数据</p>
      </ul>
    </div>

    <van-popup
      v-model="show"
      position="top"
      :style="{ width: '100%', height: '70%' }"
    >
      <div class="contentSearch">
        <p></p>
        <van-cell-group>
          <van-field
            v-model="PhysicalArchives_Code"
            label="实物档案编号"
            type="text"
            input-align="right"
            placeholder="请输入实物档案编号"
            rows="1"
            autosize
          />
          <van-field
            v-model="PhysicalArchives_Theme"
            label="主题"
            type="text"
            input-align="right"
            placeholder="请输入主题"
            rows="1"
            autosize
          />
          <van-field
            v-model="PhysicalArchives_Custodian"
            label="保管人"
            type="text"
            input-align="right"
            placeholder="请输入保管人"
            rows="1"
            autosize
          />
          <van-field
            v-model="PhysicalArchives_Room"
            label="档案库"
            type="text"
            input-align="right"
            placeholder="请输入档案库"
            rows="1"
            autosize
          />
          <van-field
            v-model="PhysicalArchives_Shelf"
            label="档案架"
            type="text"
            input-align="right"
            placeholder="请输入档案架"
            rows="1"
            autosize
          />
          <van-field
            v-model="PhysicalArchives_Box"
            label="档案盒"
            type="text"
            input-align="right"
            placeholder="请输入档案盒"
            rows="1"
            autosize
          />
          <van-field
            v-model="PhysicalArchives_StorageTime"
            label="保管时长"
            type="text"
            input-align="right"
            placeholder="请输入保管时长"
            rows="1"
            autosize
          />
          <van-field
            v-model="PhysicalArchives_Remark"
            label="备注"
            type="textarea"
            input-align="right"
            placeholder="请输入备注"
            rows="2"
            autosize
          />
        </van-cell-group>
        <van-button class="btn" type="info" @click="goSubmit"
          >档案查询</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "UploadArchives",

  data() {
    return {
      PhysicalArchives_Code: "",
      PhysicalArchives_Theme: "",
      PhysicalArchives_Custodian: "",
      PhysicalArchives_Room: "",
      PhysicalArchives_Shelf: "",
      PhysicalArchives_Box: "",
      PhysicalArchives_StorageTime: "",
      PhysicalArchives_Remark: "",

      show: false,
      searchLists: [
        {
          PhysicalArchives_Code: "XXXXX电子档案",
          PhysicalArchives_RegistrationTime: "2020-03-01 12:00",
        },
      ],
    };
  },
  methods: {
    getDZDAlists() {
      let self = this;
      let info = {
        IsSystem: false,
        IsPagination: true,
        data: [
          {
            "@ClassName": "peacepay",
            "@MethodName": "GetPhysicalArchives",
          },
          {
            PhysicalArchives_ID: "",
            PhysicalArchives_AppID: "",
            PhysicalArchives_EnterpriseID: "",
            PhysicalArchives_Code: "",
            PhysicalArchives_RegistrantID: "",
            PhysicalArchives_RegistrationTime: "",
            PhysicalArchives_ReviserID: "",
            PhysicalArchives_ReviserTime: "",
            PhysicalArchives_Theme: "",
            PhysicalArchives_Custodian: "",
            PhysicalArchives_RoomID: "",
            PhysicalArchives_Room: "",
            PhysicalArchives_ShelfID: "",
            PhysicalArchives_Shelf: "",
            PhysicalArchives_BoxID: "",
            PhysicalArchives_Box: "",
            PhysicalArchives_StorageTime: "",
            PhysicalArchives_Remark: "",
            PhysicalArchives_ISlend: "",
            PhysicalArchives_ElectronicsID: "",
            PhysicalArchives_Text1: "",
            PhysicalArchives_Text2: "",
            PhysicalArchives_Text3: "",
          },
        ],
      };
      let fd = new FormData();
      fd.append("inputJson", JSON.stringify(info));
      fd.append("page", 1);
      fd.append("limit", 1000);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      self.$axios
        .post(
          "https://beyondsky.docuvix.com/peacepay/api/UniversalAccessDB/GeneralQueryData",
          fd,
          config
        )
        .then((res) => {
          console.log(res);
          var result = res.data;
          console.log(result);
          if (result.errorCode == "1") {
            // Toast('获取成果！')
            self.searchLists = result.data;
          } else {
            Dialog.alert({
              title: "温馨提示",
              message: result.msg,
            }).then(() => {
              // on close
            });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    goBack() {
      window.history.go(-1);
    },
    goDetail(item) {
      var jsonList = {
        PhysicalArchives_Code: item.PhysicalArchives_Code,
        PhysicalArchives_Theme: item.PhysicalArchives_Theme,
        PhysicalArchives_Custodian: item.PhysicalArchives_Custodian,
        PhysicalArchives_Room: item.PhysicalArchives_Room,
        PhysicalArchives_Shelf: item.PhysicalArchives_Shelf,
        PhysicalArchives_Box: item.PhysicalArchives_Box,
        PhysicalArchives_StorageTime: item.PhysicalArchives_StorageTime,
        PhysicalArchives_Remark: item.PhysicalArchives_Remark,
      };
      this.$router.push({
        path: "/archivesDetail",
        query: {
          jsonList: JSON.stringify(jsonList),
        },
      });
    },
    openSearch() {
      this.show = true;
    },
    getCode() {
      var code = sessionStorage.getItem("code");
      console.log(code);
      if (code == "401") {
        this.$router.push("/login");
      }
    },
    goSubmit() {
      let self = this;
      let info = {
        IsSystem: false,
        IsPagination: true,
        data: [
          {
            "@ClassName": "peacepay",
            "@MethodName": "GetPhysicalArchives",
          },
          {
            PhysicalArchives_ID: "",
            PhysicalArchives_AppID: "",
            PhysicalArchives_EnterpriseID: "",
            PhysicalArchives_Code: self.PhysicalArchives_Code,
            PhysicalArchives_RegistrantID: "",
            PhysicalArchives_RegistrationTime: "",
            PhysicalArchives_ReviserID: "",
            PhysicalArchives_ReviserTime: "",
            PhysicalArchives_Theme: self.PhysicalArchives_Theme,
            PhysicalArchives_Custodian: self.PhysicalArchives_Custodian,
            PhysicalArchives_RoomID: "",
            PhysicalArchives_Room: self.PhysicalArchives_Room,
            PhysicalArchives_ShelfID: "",
            PhysicalArchives_Shelf: self.PhysicalArchives_Shelf,
            PhysicalArchives_BoxID: "",
            PhysicalArchives_Box: self.PhysicalArchives_Box,
            PhysicalArchives_StorageTime: self.PhysicalArchives_StorageTime,
            PhysicalArchives_Remark: self.PhysicalArchives_Remark,
            PhysicalArchives_ISlend: "",
            PhysicalArchives_ElectronicsID: "",
            PhysicalArchives_Text1: "",
            PhysicalArchives_Text2: "",
            PhysicalArchives_Text3: "",
          },
        ],
      };
      let fd = new FormData();
      fd.append("inputJson", JSON.stringify(info));
      fd.append("page", 1);
      fd.append("limit", 1000);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      self.$axios
        .post(
          "https://beyondsky.docuvix.com/peacepay/api/UniversalAccessDB/GeneralQueryData",
          fd,
          config
        )
        .then((res) => {
          console.log(res);
          var result = res.data;
          console.log(result);
          if (result.errorCode == "1") {
            // Toast('获取成果！')
            self.show = false;
            self.searchLists = result.data;
          } else {
            Dialog.alert({
              title: "温馨提示",
              message: result.msg,
            }).then(() => {
              // on close
            });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
  created() {
    //判断身份是否失效
    // this.getCode()
    // this.GetAllDiscernCategory()
    this.getDZDAlists();
  },
};
</script>

<style lang="less"  scoped >
.home {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.boder {
  width: 100%;
  height: 2px;
  background-color: #eee;
}
.searchLists {
  width: 100%;
  padding-top: 50px;
}
.header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
}
.contentSearch {
  width: 100%;
  height: auto;
}
.btn {
  width: 100%;
  position: absolute !important;
  bottom: 0;
  left: 0;
}
</style>