<template>
  <div class="home">
    <van-nav-bar fixed title="实物档案入库" left-arrow @click-left="goBack" />

    <van-dialog
      v-model="show"
      title="根据当前档案文件的所属的卷宗与案卷建议放入以下位置："
      show-cancel-button
      @confirm="confirm"
      confirmButtonColor = "#1989fa"
    >
      <p>库<span style="opacity:0">我</span>房：{{ da_ku }}</p>
      <p>档案架：{{ da_jia }}</p>
      <p>档案盒：{{ da_he }}</p>
    </van-dialog>

    <div class="content">
      <van-cell-group>
        <van-field
          v-model="PhysicalArchives_Code"
          label="实物档案编号"
          type="text"
          input-align="right"
          placeholder="请输入实物档案编号"
          rows="1"
          autosize
          :disabled="isdisabled"
        />
        <van-field
          v-model="PhysicalArchives_Theme"
          label="主题"
          type="text"
          input-align="right"
          placeholder="请输入主题"
          rows="1"
          autosize
        />
        <van-field
          v-model="PhysicalArchives_Custodian"
          label="档案类别"
          type="text"
          input-align="right"
          placeholder="请输入档案类别"
          rows="1"
          autosize
        />
        <van-field
          v-model="my_jia"
          label="档案库"
          type="text"
          input-align="right"
          placeholder="请输入档案库"
          rows="1"
          autosize
        />
        <van-field
          v-model="my_jia"
          label="档案架"
          type="text"
          input-align="right"
          placeholder="请输入档案架"
          rows="1"
          autosize
        />
        <van-field
          v-model="my_he"
          label="档案盒"
          type="text"
          input-align="right"
          placeholder="请输入档案盒"
          rows="1"
          autosize
        />
        <van-field
          v-model="PhysicalArchives_Remark"
          label="备注"
          type="textarea"
          input-align="right"
          placeholder="请输入备注"
          rows="2"
          autosize
        />
      </van-cell-group>
      <van-button class="btn" type="info" @click="goSubmit"
        >确定入库</van-button
      >
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";

export default {
  name: "UploadArchives",

  data() {
    return {
      show: false,
      da_ku: "DA-2021",
      da_jia: "AJ-0001",
      da_he: "CW-9992",
      my_ku: "",
      my_jia: "",
      my_he: "",
      PhysicalArchives_Code: "",
      PhysicalArchives_Theme: "",
      PhysicalArchives_Custodian: "",
      PhysicalArchives_Room: "",
      PhysicalArchives_Shelf: "",
      PhysicalArchives_Box: "",
      PhysicalArchives_StorageTime: "",
      PhysicalArchives_Remark: "",
      isdisabled: false,
    };
  },
  methods: {
    goBack() {
      window.history.go(-1);
    },
    confirm(){
      this.my_ku = this.da_ku
      this.my_jia = this.da_jia
      this.my_he = this.da_he
      console.log(this)
    },
    getCode() {
      var code = sessionStorage.getItem("code");
      console.log(code);
      if (code == "401") {
        this.$router.push("/login");
      }
    },
    goSubmit() {
      Toast("入库成功！");
      setTimeout(function(){
        window.history.go(-1);
      },2000)

      // let self = this;
      // let info = {
      //   IsSystem: false,
      //   IsPagination: true,
      //   data: [
      //     {
      //       "@ClassName": "peacepay",
      //       "@MethodName": "SavePhysicalArchives",
      //     },
      //     {
      //       PhysicalArchives_ID: "",
      //       PhysicalArchives_AppID: "",
      //       PhysicalArchives_EnterpriseID: "",
      //       PhysicalArchives_Code: self.PhysicalArchives_Code,
      //       PhysicalArchives_RegistrantID: "",
      //       PhysicalArchives_RegistrationTime: "",
      //       PhysicalArchives_ReviserID: "",
      //       PhysicalArchives_ReviserTime: "",
      //       PhysicalArchives_Theme: self.PhysicalArchives_Theme,
      //       PhysicalArchives_Custodian: self.PhysicalArchives_Custodian,
      //       PhysicalArchives_RoomID: "",
      //       PhysicalArchives_Room: self.PhysicalArchives_Room,
      //       PhysicalArchives_ShelfID: "",
      //       PhysicalArchives_Shelf: self.PhysicalArchives_Shelf,
      //       PhysicalArchives_BoxID: "",
      //       PhysicalArchives_Box: self.PhysicalArchives_Box,
      //       PhysicalArchives_StorageTime: self.PhysicalArchives_StorageTime,
      //       PhysicalArchives_Remark: self.PhysicalArchives_Remark,
      //       PhysicalArchives_ISlend: "",
      //       PhysicalArchives_ElectronicsID: "",
      //       PhysicalArchives_Text1: "",
      //       PhysicalArchives_Text2: "",
      //       PhysicalArchives_Text3: "",
      //     },
      //   ],
      // };
      // let fd = new FormData();
      // fd.append("inputJson", JSON.stringify(info));
      // fd.append("page", 1);
      // fd.append("limit", 1000);

      // let config = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };
      // self.$axios
      //   .post(
      //     "https://beyondsky.docuvix.com/peacepay/api/UniversalAccessDB/GeneralQueryData",
      //     fd,
      //     config
      //   )
      //   .then((res) => {
      //     console.log(res);
      //     var result = res.data;
      //     console.log(result);
      //     if (result.errorCode == "1") {
      //       Toast("添加成功！");
      //       setTimeout(function () {
      //         (self.PhysicalArchives_Code = ""),
      //           (self.PhysicalArchives_Theme = ""),
      //           (self.PhysicalArchives_Custodian = ""),
      //           (self.PhysicalArchives_Room = ""),
      //           (self.PhysicalArchives_Shelf = ""),
      //           (self.PhysicalArchives_Box = ""),
      //           (self.PhysicalArchives_StorageTime = ""),
      //           (self.PhysicalArchives_Remark = "");
      //       }, 2000);
      //     } else {
      //       Dialog.alert({
      //         title: "温馨提示",
      //         message: result.msg,
      //       }).then(() => {
      //         // on close
      //       });
      //     }
      //   })
      //   .catch((res) => {
      //     console.log(res);
      //   });
    },
  },
  created() {
    //判断身份是否失效
    // this.getCode()
    // this.GetAllDiscernCategory()
    this.show = true
    let codeInfo = this.$route.query.codeInfo;
    if (codeInfo !== "") {
      this.PhysicalArchives_Code = codeInfo;
      this.isdisabled = true;
    }
  },
};
</script>

<style lang="less"  scoped >

.home {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
}
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  padding-top: 46px;
  position: relative;
}
.btn {
  width: 100%;
  position: absolute !important;
  bottom: 0;
  left: 0;
}
</style>