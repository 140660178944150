<template>
  <div class="voucherServices1">
    <van-nav-bar fixed :title="title" left-arrow @click-left="goBack">
    </van-nav-bar>
    <div class="function1" v-for="(item, index) in FileLists" :key="index">
      <div class="uploadCard1" @click="goNext(item)">
        <div class="text1">
          <img src="../assets/images/icon/dah.png" alt="" />
        </div>
        <div class="littleHint1">
          {{ item.physicalLocationBasicSetUp_NodeName }}
          <p>{{item.physicalLocationBasicSetUp_NodeType}}</p>
          <!-- <p>
            <span>{{ item.count }}</span
            >份档案
          </p> -->
        </div>
      </div>
    </div>
    <van-button class="btn" type="info" @click="show = true"
      >新增档案盒</van-button
    >

    <van-popup v-model="show" style="width: 100%">
      <div class="openMain">
        <h4>添加档案盒</h4>
        <p>
          <van-field
            v-model="value"
            left-icon="search-o"
            placeholder="请输入档案盒名称"
          />
        </p>
        <div>
          <van-button class="smallBtn btnsQX"  @click="show = false"
            >取消</van-button
          >
          <van-button class="smallBtn rightBtn" type="info"  @click="addDa"
            >确定</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";

export default {
  name: "HeLists",
  data() {
    return {
      show: false,
      title: "",
      value:'',
      physicalLocationBasicSetUp_NodeID:'',
      FileLists: [
      ],
    };
  },
  created() {
    let title = this.$route.query.title;
    let physicalLocationBasicSetUp_NodeID = this.$route.query.physicalLocationBasicSetUp_NodeID;
    this.physicalLocationBasicSetUp_NodeID = physicalLocationBasicSetUp_NodeID
    this.title = title;
    this.getXnkf()
  },
  methods: {
    addDa() {
      this.show = false;
      let self = this;
      var item = {
        "physicalLocationBasicSetUp_NodeName":self.value,
        "physicalLocationBasicSetUp_NodeType":'档案盒'
      }
      self.FileLists.unshift(item)
      self.value = ''

    },
    getXnkf() {
      let self = this;
      let info = {
        IsSystem: false,
        IsPagination: true,
        data: [
          {
            "@ClassName": "peacepay",
            "@MethodName": "GetphysicalLocationBasicSetUp",
          },
          {
            physicalLocationBasicSetUp_NodeID: self.physicalLocationBasicSetUp_NodeID,
            physicalLocationBasicSetUp_NodeType: "档案盒",
            physicalLocationBasicSetUp_NodeName: "",
            physicalLocationBasicSetUp_ParentID: "",
           
          },
        ],
      };
      let fd = new FormData();
      fd.append("inputJson", JSON.stringify(info));
      fd.append("page", 1);
      fd.append("limit", 1000);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      self.$axios
        .post(
          "https://beyondsky.docuvix.com/peacepay/api/UniversalAccessDB/GeneralQueryData",
          fd,
          config
        )
        .then((res) => {
          console.log(res);
          var result = res.data;
          console.log(result);
          if (result.errorCode == "1") {
            // Toast('获取成功！')
            // self.show = false;
            self.FileLists = result.data;
          } else {
            Dialog.alert({
              title: "温馨提示",
              message: result.msg,
            }).then(() => {
              // on close
            });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    goBack() {
      window.history.go(-1);
    },
    goNext(item) {
      this.$router.push({
        path: "/FileLists",
        query: {
          title: item.physicalLocationBasicSetUp_NodeName,
          physicalLocationBasicSetUp_NodeID:
            item.physicalLocationBasicSetUp_NodeID,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped >
.openMain {
  height: 150px;
}
.smallBtn {
  width: 50%;
  position: absolute !important;
  bottom: 0;
  left: 0;
}
.rightBtn{
  left: initial;
  right: 0;
}
.btn {
  width: 100%;
  position: absolute !important;
  bottom: 0;
  left: 0;
}

.voucherServices1 {
  box-sizing: border-box;
  padding-top: 45px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f4f4f4;

  .header {
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin-bottom: 10px;
    // color: #fff;
    // background-color: #1989fa;
    // background: linear-gradient(to right, #0689ff, #4c3dfc);
    position: relative;

    .close {
      height: 44px;
      padding-top: 4px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 15px;
    }
  }

  .function1 {
    width: 100%;
    height: 130px;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    text-align: left;

    .uploadCard1 {
      flex: 1;
      padding: 15px;
      margin-right: 0;
      background-image: linear-gradient(to right, #fff, #fff) !important;
      border-radius: 10px;
      box-sizing: border-box;
      position: relative;
      box-shadow: 2px 2px 8px #8a878759;
    }
    .text1 {
      width: 80px !important;
      height: 80px !important;
      background-color: royalblue;
      float: left;
      margin: 0;
      border-radius: 50%;
      img {
        width: 45px !important;
        height: 45px!important;
        display: block!important;
        margin: 15px auto!important;
      }
    }
    .littleHint1 {
      display: inline-block;
      font-size: 24px;
      margin-left: 10px;
      line-height: 26px;
      margin-top: 14px;
      background: none;
      font-weight: 600 !important;
      color: #333 !important;
      text-align: left;
      padding: 2px 5px;
      border-radius: 5px;
      p {
        margin: 0;
        margin-top: 10px;
        font-size: 14px;
        color: #666;
      }
    }
  }
}
</style>